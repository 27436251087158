//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import I18Switch from '@/views/components/I18nSwitch/index.vue';
import { mapState } from 'vuex';
import Card from '@/views/components/homeCard/index.vue';
import { getFaqList } from '@/api/home';
import { ERROR_OK } from '@/api/config';
import ProductCart from '../components/productCard/index.vue';
import GetStated from './comp/getStarted.vue';

export default Vue.extend({
  name: 'Internationalization',
  components: {
    I18Switch, GetStated, Card, ProductCart,
  },
  data() {
    return {
      faqTitle: '',
      faqList: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      hasLogin: (state) => state.user.token,
      userInfo: (state) => state.user.userInfo,
    }),
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 90;
        case 'sm': return 100;
        case 'md': return 150;
        case 'lg': return 150;
        case 'xl': return 150;
        default: return 150;
      }
    },
  },
  methods: {
    init() {
      getFaqList().then((res) => {
        if (res.code === ERROR_OK) {
          const { faq_page_title: FaqTitle, faqList } = res.data;
          this.faqTitle = FaqTitle;
          this.faqList = faqList.map((item) => ({
            ...item,
            close: false,
          }));
        }
      });
    },
    handleSignIn() {
      this.$router.push({ path: '/login' });
    },
    handleToMy() {
      this.$router.push({ path: '/user/my' });
    },
    handleLogout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.replace({
          path: '/',
        });
        window.location.reload();
      });
    },
  },
});
