//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { getTestLine } from '@/api/home';
import { ERROR_OK } from '@/api/config';

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      email: '',
      emailRules: [
        (v) => !!v || this.$t('home_page_header_label1'),
        (v) => /.+@.+\..+/.test(v) || this.$t('home_page_header_label2'),
      ],
      code: '',
      codeRules: [(v) => v.length === 4 || 'error'],
      codeDisabled: false,
      buttonText: 'send code',
      dialog: false,
      testLineTitle: '',
      testLine: '',
    };
  },
  computed: {
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        case 'sm': return true;
        case 'md': return false;
        case 'lg': return false;
        case 'xl': return false;
        default: return false;
      }
    },
    popWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '95%';
        case 'sm': return '95%';
        case 'md': return '500px';
        case 'lg': return '500px';
        case 'xl': return '500px';
        default: return '90%';
      }
    },
    whatsapp() {
      return this.$store.state.user.whatsapp || 'https://wa.me/17156029888?text=I%20want%20to%20test';
    },
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        getTestLine({ email: this.email }).then((res) => {
          if (res.code === ERROR_OK) {
            const { testLineTitle, testLineDetail } = res.data;
            this.testLineTitle = testLineTitle;
            this.testLine = testLineDetail;
            this.dialog = true;
          }
        });
      }
    },
  },
});
